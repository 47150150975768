export const objectHasData = (data) => Object.values(data).some((value) => value !== null)

export const addExcludingChinaToAsia = (value: string) =>
  value.toUpperCase() === 'ASIA' ? 'Asia (excl. China)' : value

export const searchItemsExcludingChinaToAsia = (items) =>
  items.map((item) => {
    return {
      ...item,
      name: addExcludingChinaToAsia(item.name),
    }
  })

export const replaceRegionChinaUrl = (url: string, name: string) => {
  if (name.toUpperCase() === 'CHINA') {
    return url.replace('regions', 'countries').replace('asia', 'china')
  }

  return url
}

export const VARIATION_STATE = Object.freeze({
  UP: 'UP',
  DOWN: 'DOWN',
  EQUAL: 'EQUAL',
})

export const getVariationState = (variation: number) => {
  if (variation > 0) return VARIATION_STATE.UP
  if (variation < 0) return VARIATION_STATE.DOWN
  return VARIATION_STATE.EQUAL
}

export const getVariationClassColorByState = (state: 'UP' | 'DOWN' | 'EQUAL') => {
  switch (state) {
    case VARIATION_STATE.UP:
      return getVariationClassColor(1)
    case VARIATION_STATE.DOWN:
      return getVariationClassColor(-1)
    default:
      return getVariationClassColor(0)
  }
}

export const getVariationClassColor = (variation: number) => {
  const variantState = getVariationState(variation)
  switch (variantState) {
    case 'UP':
      return 'text-p-green-400 price-up'
    case 'DOWN':
      return 'text-s-red-300 price-down'
    default:
      return 'text-n-gray-150 price-normal'
  }
}

export const getVariationStyle = (variation: number) => {
  const trend = getVariationState(variation)
  switch (trend) {
    case 'UP':
      return 'price-up icon-price-up'
    case 'DOWN':
      return 'price-down icon-price-down'
    default:
      return 'price-normal icon-price-normal'
  }
}
